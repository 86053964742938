@media print {
  * {
    box-shadow: none !important;
    padding: 0 !important;
    border: none !important;
    border-width: 0 !important;
    text-decoration: none !important;
  }
  *:empty {
    display: none;
  }
  body, .MainLayout-wrapper-1, #root {
    overflow: visible !important;
    height: auto !important;
  }
  .mainPanel {
    overflow: visible !important;
    height: auto !important;
    background-color: white;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    font-size: 14px;
    line-height: 18px;
  }
  .mainContent {
    margin: 0 !important;
    padding: 0 !important;
  }
  .mainContent button {
    display: none !important;
  }
  svg {
    page-break-inside: avoid !important;
  }
  header.TopoAppBar {
    width: 100%;
    position: relative;
  }
  .hidePrint {
    display: none !important;
  }
  label {
    margin: 5px 0 !important;
  }
  input, textarea, b {
    margin: 5px !important;
  }
}